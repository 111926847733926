<template>
  <SiteMain>
    <Faq :title="page.title" :description="page.introduction" :filterDefault="faqDefault">
      <div class="contact-cta--btn">
        <p class="text-small">{{ $t("Didn't find what you are looking for?") }}</p>
        <router-link to="/contact" class="btn-default">
          <span> {{ $t('Get in touch') }} </span>
        </router-link>
      </div>
    </Faq>
    <CtaBlock v-if="cta != null" :title="cta.title" :text="cta.text" :buttonText="cta.button_text"
              :buttonLink="cta.button_url"/>
  </SiteMain>
</template>

<script>
import SiteMain from "@/components/SiteMain.vue";
import PageLayout from "@/components/PageLayout.vue";
import Faq from "@/components/Faq.vue";
import CtaBlock from "../components/CtaBlock";

export default {
  components: {
    SiteMain,
    PageLayout,
    CtaBlock,
    Faq,
  },
  data() {
    return {
      faqDefault: this.$route.query.faqDefault,
    };
  },
  computed: {
    page(){
      return this.$store.state.cache.data[`faq${this.$i18n.locale}`]?.page ?? '';
    },
    cta() {
      const page = this.$store.state.cache.data[`faq${this.$i18n.locale}`]?.page;
      return page?.cta?.length > 0 ? page.cta[0] : null;
    }
  },
  methods: {
    async loadPage() {
      const key = `faq${this.$i18n.locale}`;
      const url = `${process.env.VUE_APP_API_URL}/api/pages/get?locale=${this.$i18n.locale}&page=faq`;
      this.$store.commit('cacheRequest', {key, url});
    },
  },
  created() {
    this.loadPage();
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadPage();
    },
  },
};
</script>

<style lang="scss">
.contact-cta--btn {
  padding: 32px 0 74px 0;
  text-align: center;
  font-size: 14px;

  p {
    color: #a4aaae;
    margin-bottom: 16px;
  }
}
</style>
